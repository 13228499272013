$backgroundColor:   #222;
$bodyFont:          Oxanium, sans-serif;
$bodyFontSize:      16pt;
$bodyColor:         #dddddd;
$codeColor:         #00a600;
$codeFontSize:      10pt;
$menuFontSize:      14pt;
$menuIndexColor:    #ff9900;
$menuIndexHover:    #ffaa00;
$menuHeaderCorrente:#ffffff;
$mainWidth:         1280px;
$linkColor:         #ff9900;
$titleColor:        #ff9900;
$quotesColor:       #ffffff;
$borderColor:       #8E8E8E;
$emColor:           #ffffff;
$labelColor:        #ffffff;
$headerHeight:      100px;
$pageWidth:         1024px;
$logoHeight:        150px;

@import url('https://fonts.googleapis.com/css?family=Oxanium:200,400,600');

* {
    -webkit-box-sizing: border-box;
       -moz-box-sizing: border-box;
            box-sizing: border-box;
               padding: 0;
                margin: 0;
}

/** Body a tutta pagina, centrato */
BODY {
    background:  $backgroundColor;
    color:       $bodyColor;
    font-family: $bodyFont;
    font-size:   $bodyFontSize;
    text-align:  center;
    width:       100%;
    margin:      0;
    position:    absolute;
}

H1, H2, H3, H4 {
    color: $titleColor;
    font-weight: bold;
}

H1 {
    padding: 2rem 0;
    font-size: 36px;
}
H2 {
    padding: 2rem 0 0rem;
    font-size: 24px;
}
H3 {
    font-size: 18px;
    padding: 2rem 0 rem;
}

UL :first-child, 
OL :first-child {
    margin-top: 0;
}

A {
    color: $linkColor; 
}
A#top {
    display: inline-block;
    padding-bottom: 2rem;
}

NAV A {
	display: inline-block;
	padding: 0 5px;
	font-weight: bold;
    text-transform: capitalize;
    color: $linkColor; 
}
NAV#menu-lang IMG {
	border: none;
	width: 24px;
}
NAV#menu-lang A.corrente IMG {
	filter: drop-shadow(2px 4px 6px black);
}

MAIN, HEADER, FOOTER {
    width:       $pageWidth;
    max-width:   100%;
    margin:      auto;
    position:    relative;
    padding:     0px;
}
MAIN {
    padding: 1rem 0 3rem;
}

/**
 *  Header
 */
HEADER {
    text-align: left;
}

HEADER IMG#logo {
    display: inline-block;
    position: relative;
    height: $logoHeight;
    margin: 10px 10px 0 10px;
}
HEADER NAV#menu-header {
    display: inline-block;
    position: absolute;
    right: 0;
    left: auto;
    bottom: 2rem;
    border-bottom: solid 1px #999;
}
HEADER NAV#menu-header A {
    text-decoration: none;
    font-weight: 400;
    font-size: $menuFontSize;
}
HEADER NAV#menu-header A.corrente {
    position: relative;
    bottom: -2rem;
    color: $menuHeaderCorrente;
    font-weight: bold;
}

/**
 *  Footer
 */
FOOTER {
	text-align: left;
    border-top: solid 2px $borderColor;
    padding: 2rem 0;
}

FOOTER NAV {
	position: relative;
    display: inline-block;
}

FOOTER NAV#menu-lang {
    float: right;
}
FOOTER NAV#menu-footer {
	left: 0;
    font-size: 0.9rem;
}

FOOTER NAV#menu-footer A {
    text-decoration: none;
    font-weight: 400;
    font-size: $menuFontSize;
}

/**
 *  Articolo
 */
MAIN ARTICLE {
    display: block;
    display: inline-block;
    margin: auto auto 30px auto;
    overflow: auto;
    position: relative;
    width: calc(100% - 320px);
    padding: 0 1rem;
}
MAIN ASIDE {
    display: inline-block;
    float: left;
}
MAIN ASIDE IMG.main {
    width: 300px;
    border: solid 1px $borderColor;
}
MAIN ARTICLE SECTION {
    overflow: auto;
}
MAIN ARTICLE H3 {
    margin: 1rem auto 0rem auto;
}
MAIN ARTICLE > P {
    text-align: justify;
    line-height: 1.5;
}
MAIN ARTICLE BLOCKQUOTE {
    padding: 1rem 0;
    font-style: italic;
    color: $quotesColor;
    text-align: center;
}
MAIN ARTICLE  P:has(> IMG) {
    text-align: center;
}
MAIN ARTICLE B, 
MAIN ARTICLE STRONG, 
MAIN ARTICLE P EM {
    color: $emColor;
    font-weight: 600;
}
MAIN ARTICLE P IMG {
    margin: 20px auto;
    padding:4px;
    border: solid 1px $borderColor;
    max-width: 90%;
    display: block;
}
MAIN ARTICLE UL ,
MAIN ARTICLE OL {
    margin: 1rem auto 1rem auto;
    padding-left: 1rem;
    font-size: 1em;
    text-align: left;
    display: inline-block;
}
ARTICLE PRE {
    display: block;
    overflow-x: scroll;
}
ARTICLE PRE CODE {
    color: $codeColor;
    font-size: $codeFontSize;  
    text-align: left;
    display: inline-block;
    margin: 2rem auto;
}

/** Pagina Tacun */
MAIN TABLE {
    margin: 0.5rem auto;
    
}
MAIN TABLE TR TD {
    text-align: left;
    padding: 0.25rem 0.5rem;
}
MAIN TABLE TR TD:first-of-type {
    text-align: right;
    font-weight: bold;
    color: $labelColor;
    min-width: 5rem; // stelle Ecolock
    vertical-align: top;
}

/**
*
*   INDEX
*/
BODY.index HEADER,
BODY.index FOOTER {
    text-align: center;
    border: none;
    height: 30vh;
    padding: 16px;
    margin: 0 auto;
}
BODY.index HEADER IMG#logo {
    margin: auto;
}
BODY.index HEADER P.motto {
    font-size: 0.9rem;
    color: $quotesColor;
}
BODY.index MAIN {
    position: relative;
    padding: 0;
    height: 40vh;
    width: 1280px;
    overflow: hidden;
}
BODY.index DIV#menu-cont {
    display:      block;
    text-align:   center;
    width:        100%;
    top:          calc((100% - 52px) / 2);
    position:     relative;
}
BODY.index NAV#menu-lang {
    float: none;
    top: calc(100% - 30px);
}

NAV#menu-index {
    background-color: rgba(255,255,255,0.0);
    height:   3.2rem;
    position: relative;
    width:    100%;
}
NAV#menu-index SPAN {
    background-color: rgba(255,255,255,0.0);
    display:          inline-block;
    font-size:        16pt;
    line-height:      1.2;
    width:            10%;
    padding:          1rem;
    position:         absolute;
    text-transform:   uppercase;
    animation-duration:           6s;
    animation-timing-function:    ease-in-out;
    animation-direction:          alternate;
    animation-iteration-count:    2;    /* deve essere un numero pari */
}

NAV#menu-index SPAN A {
    color:            $menuIndexColor;
    font-weight:      200;
    text-decoration:  none;
    transition:       bold 1s;
}

NAV#menu-index SPAN A:hover {
    color:            $menuIndexHover;
    font-weight:      400;
}


/** Animazione degli elementi del menu */

BODY.index SPAN#menu-1 {
    left:             5%;
    animation-name:   menu-1;
    animation-delay:  0s;
}

BODY.index SPAN#menu-2 {
    left:             25%;
    animation-name:   menu-2;
    animation-delay:  2s;
}

BODY.index SPAN#menu-3 {
    left:             45%;
    animation-name:   menu-3;
    animation-delay:  4s;
}

BODY.index SPAN#menu-4 {
    left:             65%;
    animation-name:   menu-4;
    animation-delay:  4s;
}

BODY.index SPAN#menu-5 {
    left:             85%;
    animation-name:   menu-5;
    animation-delay:  4s;
}

/**
*   PAGINE ELENCO
*/
NAV.menu DIV.post P.excerpt {
    text-align: left;
}

/**
*
*   MOVIE CAPTCHA
*/
DIV#frm-captcha {
    margin: 2rem auto;
	-webkit-transition: all 3s ease-out;
	   -moz-transition: all 3s ease-out;
		 -o-transition: all 3s ease-out;
		    transition: all 3s ease-out;
}
DIV#frm-captcha.hidden {
    opacity: 0;
    height: 0;
	overflow: hidden;
	display: none;
}

IMG#img-captcha {
	width:200px;
	height:300px;
    line-height: 300px;
	border:solid 1px;
	display: block;
	margin: 0 auto;
}
INPUT#tit-captcha {
    font-size: 1rem;
	width: 300px;
	margin: 1rem auto;
    border: solid 1px #999;
    padding: 0.25rem;
}

INPUT#btt-ok ,
INPUT#btt-captcha {
	background-color: #ff9900;
	color: #fff;
	padding: 0.5rem;
    border: solid 1px #999;
    border-bottom-right-radius: 10px;
    font-family: Verdana, sans-serif;
    font-size: 1rem;
    margin: 0;
    padding: 0.25rem 0.5rem;
    width: auto;
}
INPUT#btt-ok {
    display:none;
    margin-top:2rem;    
}

/**
*   Pagina Contatti
*/
TABLE#contatti {
    margin: 1rem auto;
}

@media (max-width: 1023px) {
    BODY.index DIV#menu-cont {
        top: 0;
    }
    NAV#menu-index {
        height: auto;
    }   
    NAV#menu-index SPAN {   
        display:          block;
        font-size:        16pt;
        line-height:      1.2;
        width:            auto;
        padding:          1rem;
        position:         relative;
    }
    BODY.index NAV#menu-index SPAN {
        left:             auto;
        animation-name:   unset;
        animation-delay:  unset;
    }    
    BODY.index NAV#menu-index SPAN#menu-1 {
        animation-name:   menu-mobile-dx;
    }
    BODY.index NAV#menu-index SPAN#menu-2 {
        animation-name:   menu-mobile-sx;
    }
    BODY.index NAV#menu-index SPAN#menu-3 {
        animation-name:   menu-mobile-dx;
    }
    BODY.index NAV#menu-index SPAN#menu-4 {
        animation-name:   menu-mobile-sx;
    }
    BODY.index NAV#menu-index SPAN#menu-5 {
        animation-name:   menu-mobile-dx;
    }
    BODY.index NAV#menu-index SPAN#menu-6 {
        animation-name:   menu-mobile-sx;
    }
    BODY.index NAV#menu-lang {
        top: calc((100% - 30px) / 2);
    }
    MAIN ARTICLE {
        display: block;
        margin: 16px 16px 32px 16px;
        width: auto;
    }
    ARTICLE  {
        overflow: auto;
    }
    LI{
        padding-bottom: 0.5rem;
    }
    /** Tabelle nella pagina di Modella */
    MAIN TABLE TR TD {
        display: block;
    }
    MAIN TABLE TR TD:first-of-type {
        text-align: left;
    }
    HEADER {
        text-align: center;
        padding: 16px;
        height: auto;
    }
    HEADER IMG#logo {
        display: block;
        margin: auto;
        margin-bottom: 1rem;
    }
    HEADER NAV#menu-header {
        display: block;
        position: relative;
        bottom: unset;
    }
    HEADER NAV#menu-header A {
        width: 22%;
    }
    HEADER NAV#menu-header A.corrente {
        position: absolute;
        width: auto;
    }
    
    A#menu-1.corrente { left: 0% }
    A#menu-2.corrente { left: 15% }
    A#menu-3.corrente { left: 40% }
    A#menu-4.corrente { left: 60% }
    A#menu-5.corrente { left: 80% }
    
}
@media (max-width: 1023px) and (orientation: portrait) {
}
@media (max-width: 1023px) and (orientation: landscape) {
    BODY.index MAIN,
    BODY.index HEADER,
    BODY.index FOOTER {
        height: auto;
        min-height: 120px;
    }
    BODY.index NAV#menu-lang {
        top: calc((120px - 30px) / 2);
    }
}

@keyframes menu-mobile-dx {
     0%  { left:  0%; }
   100%  { left: 10%; }
}
@keyframes menu-mobile-sx {
     0%  { left:  0%; }
   100%  { left: -10%; }
}
@keyframes menu-1 {
     0%  { left:  5%; }
   100%  { left: 15%; }
}
@keyframes menu-2 {
     0%  { left: 25%; }
   100%  { left: 35%; }
}
@keyframes menu-3 {
     0%  { left: 45%; }
   100%  { left: 55%; }
}
@keyframes menu-4 {
     0%  { left: 65%; }
   100%  { left: 75%; }
}
@keyframes menu-5 {
     0%  { left: 85%; }
   100%  { left: 95%; }
}
